import { isNil } from 'lodash-es';

export const customFixed = (value: number | undefined | null, fixed: number = 2) => {
  if (isNil(value)) {
    return value;
  }
  return Number(value.toFixed(fixed));
};

export enum TimePeriod {
  SUMMIT, // 尖
  PEAK, // 峰
  PLAIN, // 平
  VALLEY, // 谷
}

export const TimePeriodLabelMap = {
  [TimePeriod.SUMMIT]: '尖',
  [TimePeriod.PEAK]: '峰',
  [TimePeriod.PLAIN]: '平',
  [TimePeriod.VALLEY]: '谷',
};

export const TimePeriodColorMap = {
  [TimePeriod.SUMMIT]: '#FF6D2C',
  [TimePeriod.PEAK]: '#CE90D1',
  [TimePeriod.PLAIN]: '#0DB6D9',
  [TimePeriod.VALLEY]: '#63BC7F',
};
