import { ColumnsType } from 'antd/es/table';
import { EllipsisSpan } from '@maxtropy/components';
import React from 'react';
import { ResolutionEnum } from '../index';
import { V2ReportListPostResponse } from '@maxtropy/dmes-apis-v2';
import dayjs from 'dayjs';
import { customFixed } from '../const';
import styles from './index.module.scss';

type DataItem = Exclude<V2ReportListPostResponse['pvStationDataList'], undefined>[number];

export const getDataColumns = (resolution: ResolutionEnum, showIncome: boolean, showTimePeriod: boolean) => {
  const list: ColumnsType<DataItem> = [
    {
      title: '日期',
      key: 'ts',
      ellipsis: { showTitle: true },
      fixed: 'left',
      width: 150,
      render: (_, record) => (
        <EllipsisSpan
          value={dayjs(record.ts).format(resolution === ResolutionEnum.DAY ? 'YYYY-MM-DD' : 'YYYY-MM')}
        ></EllipsisSpan>
      ),
    },
    {
      title: '并网点总发电量(kWh)',
      children: [
        {
          title: '总-发电量',
          dataIndex: 'totalChargeGeneration',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
        },
        {
          title: <span className={styles.summitColor}>尖-发电量</span>,
          dataIndex: 'summitChargeGeneration',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.peakColor}>峰-发电量</span>,
          dataIndex: 'peakChargeGeneration',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.plainColor}>平-发电量</span>,
          dataIndex: 'plainChargeGeneration',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.valleyColor}>谷-发电量</span>,
          dataIndex: 'valleyChargeGeneration',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
      ],
    },
    {
      title: '上网电量(kWh)',
      children: [
        {
          title: '总-上网电量',
          dataIndex: 'totalChargeOnGrid',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
        },
        {
          title: <span className={styles.summitColor}>尖-上网电量</span>,
          dataIndex: 'summitChargeOnGrid',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.peakColor}>峰-上网电量</span>,
          dataIndex: 'peakChargeOnGrid',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.plainColor}>平-上网电量</span>,
          dataIndex: 'plainChargeOnGrid',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.valleyColor}>谷-上网电量</span>,
          dataIndex: 'valleyChargeOnGrid',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
      ],
    },
    {
      title: '消纳电量(kWh)',
      children: [
        {
          title: '总-消纳电量',
          dataIndex: 'totalChargeConsumption',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
        },
        {
          title: '消纳率',
          dataIndex: 'consumptionRate',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={v}></EllipsisSpan>,
        },
        {
          title: <span className={styles.summitColor}>尖-消纳电量</span>,
          dataIndex: 'summitChargeConsumption',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.peakColor}>峰-消纳电量</span>,
          dataIndex: 'peakChargeConsumption',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.plainColor}>平-消纳电量</span>,
          dataIndex: 'plainChargeConsumption',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.valleyColor}>谷-消纳电量</span>,
          dataIndex: 'valleyChargeConsumption',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
      ],
    },
    {
      title: '上网收益(元)',
      hidden: !showIncome,
      children: [
        {
          title: '总-上网收益',
          dataIndex: 'onlineIncome',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
        },
        {
          title: <span className={styles.summitColor}>尖-上网收益</span>,
          dataIndex: 'summitOnlineIncome',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.peakColor}>峰-上网收益</span>,
          dataIndex: 'peakOnlineIncome',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.plainColor}>平-上网收益</span>,
          dataIndex: 'plainOnlineIncome',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.valleyColor}>谷-上网收益</span>,
          dataIndex: 'valleyOnlineIncome',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
      ],
    },
    {
      title: '消纳收益(元)',
      hidden: !showIncome,
      children: [
        {
          title: '总-消纳收益',
          dataIndex: 'consumptionIncome',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
        },
        {
          title: <span className={styles.summitColor}>尖-消纳收益</span>,
          dataIndex: 'summitConsumptionIncome',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.peakColor}>峰-消纳收益</span>,
          dataIndex: 'peakConsumptionIncome',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.plainColor}>平-消纳收益</span>,
          dataIndex: 'plainConsumptionIncome',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
        {
          title: <span className={styles.valleyColor}>谷-消纳收益</span>,
          dataIndex: 'valleyConsumptionIncome',
          ellipsis: { showTitle: true },
          render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
          hidden: !showTimePeriod,
        },
      ],
    },
    {
      title: '补贴收益(元)',
      dataIndex: 'subsidyIncome',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
      hidden: !showIncome,
    },
    {
      title: '总收益(元)',
      dataIndex: 'income',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
      hidden: !showIncome,
    },
  ];
  list.forEach((item: any) => {
    if (item.children) {
      item.children.forEach((child: any) => {
        if (!child.width) child.width = 110;
      });
    } else {
      if (!item.width) item.width = 110;
    }
  });

  return list;
};
