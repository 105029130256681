import styles from './index.module.scss';
import { Button, Checkbox, Form, Paging, Table, Tag, usePaging, useUpdate } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import qs from 'qs';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ColumnProps } from 'antd/es/table';
import { v4 } from 'uuid';
import DateRangePicker from '../DateRangePicker';
import { useHasPermission } from '@/utils/hooks';
import { PermissionsType } from '@/common/permissionsConst';
import {
  apiV2MeterReadingPagePost,
  apiV2MeterReadingPvMeterPagePost,
  apiV2MeterReadingReImplementPost,
  V2MeterReadingPagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import { TimePeriod, TimePeriodColorMap, TimePeriodLabelMap } from '../../const';

type DataItem = Exclude<V2MeterReadingPagePostResponse['list'], undefined>[number];
type TableDataItem = {
  time?: string;
  deviceName?: string;
  timeRowSpan: number;
  magnification?: number;
  deviceNameRowSpan: number;
  timePeriod: TimePeriod;
} & Exclude<DataItem['dataList'], undefined>[number]['summitNumericalDisplay'];

interface IElecRecord {
  timeRange: [Dayjs, Dayjs];
  setTimeRange: (timeRange: [Dayjs, Dayjs]) => void;
  pvId: number | undefined;
}

const ElecRecord: FC<IElecRecord> = props => {
  const { timeRange, setTimeRange, pvId } = props;

  const [display, setDisplay] = useState(true); // 每日抄表
  const [hideExtendColumn, setHideExtendColumn] = useState(false);

  const pagingInfo = usePaging(20);
  const { pageOffset, setPageOffset, pageSize, setTotalCount } = pagingInfo;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<V2MeterReadingPagePostResponse['list']>([]);

  const [xx, update] = useUpdate();

  const hasPermissionHideExtendColumn = useHasPermission(
    PermissionsType.B_BSA_HIDDEN_INDICATOR_MULTIPLIED_BY_MULTIPLIER
  ); // 显示隐藏倍率列
  const hasPermissionReImplement = useHasPermission(PermissionsType.B_METER_READING);

  const tableData = useMemo(() => {
    const tableData: TableDataItem[] = [];
    data?.forEach(dataItem => {
      const dataListLength = dataItem.dataList?.length ?? 0;
      dataItem.dataList?.forEach((detailItem, index) => {
        tableData.push({
          time: dataItem.time,
          deviceName: detailItem.deviceName,
          magnification: detailItem.magnification,
          ...detailItem.summitNumericalDisplay,
          timeRowSpan: index === 0 ? dataListLength * 4 : 0,
          deviceNameRowSpan: 4,
          timePeriod: TimePeriod.SUMMIT,
        });
        tableData.push({
          time: dataItem.time,
          deviceName: detailItem.deviceName,
          magnification: detailItem.magnification,
          ...detailItem.peakNumericalDisplay,
          timeRowSpan: 0,
          deviceNameRowSpan: 0,
          timePeriod: TimePeriod.PEAK,
        });
        tableData.push({
          time: dataItem.time,
          deviceName: detailItem.deviceName,
          magnification: detailItem.magnification,
          ...detailItem.plainNumericalDisplay,
          timeRowSpan: 0,
          deviceNameRowSpan: 0,
          timePeriod: TimePeriod.PLAIN,
        });
        tableData.push({
          time: dataItem.time,
          deviceName: detailItem.deviceName,
          magnification: detailItem.magnification,
          ...detailItem.valleyNumericalDisplay,
          timeRowSpan: 0,
          deviceNameRowSpan: 0,
          timePeriod: TimePeriod.VALLEY,
        });
      });
    });
    return tableData;
  }, [data]);

  const fetchData = () => {
    if (!pvId) return;
    setLoading(true);
    apiV2MeterReadingPvMeterPagePost({
      page: pageOffset,
      size: pageSize,
      pvStationId: pvId,
      startTime: timeRange[0].startOf('d').unix().toString(),
      endTime: timeRange[1].startOf('d').unix().toString(),
      display: display,
    })
      .then(res => {
        setData(res?.list ?? []);
        setTotalCount(res?.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setPageOffset(1);
    update();
  }, [pvId, timeRange, display]);

  useEffect(() => {
    fetchData();
  }, [xx, pageOffset, pageSize]);

  const columns: ColumnProps<TableDataItem>[] = [
    {
      title: '抄表时间',
      dataIndex: 'time',
      fixed: 'left',
      width: 150,
      render: value => {
        return (
          <div>
            <div style={{ marginBottom: 8 }}>{dayjs(value).format('YYYY-MM-DD HH:mm:ss')}</div>
            {hasPermissionReImplement && <ReImplementButton pvId={pvId!} time={value} onFinish={fetchData} />}
          </div>
        );
      },
      onCell: record => {
        return {
          rowSpan: record.timeRowSpan,
        };
      },
    },
    {
      title: '电表',
      dataIndex: 'deviceName',
      fixed: 'left',
      width: 150,
      render: (_, record) => {
        return (
          <div>
            <div style={{ marginBottom: 8 }}>{record.deviceName}</div>
            <Tag border="solid" color="info">
              倍率：{record.magnification}
            </Tag>
          </div>
        );
      },
      onCell: record => {
        return {
          rowSpan: record.deviceNameRowSpan,
        };
      },
    },
    {
      title: '峰谷时段',
      key: 'timePeriod',
      render: (_, record) => {
        return (
          <span
            style={{
              color: TimePeriodColorMap[record.timePeriod],
            }}
          >
            {TimePeriodLabelMap[record.timePeriod]}
          </span>
        );
      },
    },
    {
      title: '正向示数',
      dataIndex: 'forwardReading',
    },
    {
      title: '反向示数',
      dataIndex: 'reverseReading',
    },
    ...(hideExtendColumn
      ? []
      : [
          {
            title: '正向示数*倍率',
            dataIndex: 'multipliedForwardReading',
          },
          {
            title: '反向示数*倍率',
            dataIndex: 'multipliedReverseReading',
          },
        ]),
  ];

  return (
    <div className={styles.wrap}>
      <div className={styles.top}>
        <Form isOrigin layout="inline">
          <Form.Item label="抄表日期：">
            <DateRangePicker
              timeRange={timeRange}
              setTimeRange={setTimeRange}
              maxRange={366}
              type="date"
            ></DateRangePicker>
          </Form.Item>
          <Form.Item>
            <Checkbox checked={display} onChange={e => setDisplay(e.target.checked)}>
              展示每日抄表
            </Checkbox>
          </Form.Item>
          {hasPermissionHideExtendColumn && (
            <Form.Item>
              <Checkbox checked={hideExtendColumn} onChange={e => setHideExtendColumn(e.target.checked)}>
                隐藏示数*倍率列
              </Checkbox>
            </Form.Item>
          )}
        </Form>
        <Button
          type="primary"
          wrapClassName={styles.export}
          disabled={!pvId}
          onClick={() => {
            window.open(
              `/api/v2/meter-reading/pv/download` +
                qs.stringify(
                  {
                    id: pvId,
                    display: display,
                    startTime: timeRange[0].startOf('d').unix().toString(),
                    endTime: timeRange[1].startOf('d').unix().toString(),
                  },
                  { addQueryPrefix: true }
                )
            );
          }}
        >
          导出
        </Button>
      </div>

      <Table
        bordered
        rowKey={() => v4()}
        scroll={{ y: 'calc(100vh - 380px)', x: 'max-content' }}
        dataSource={tableData}
        columns={columns}
        loading={loading}
      />
      <Paging pagingInfo={pagingInfo} />
    </div>
  );
};

const ReImplementButton = (props: { pvId: number; time: string; onFinish: () => void }) => {
  const { pvId, time, onFinish } = props;
  const [loading, setLoading] = useState(false);

  const onReImplement = () => {
    setLoading(true);
    apiV2MeterReadingReImplementPost({
      // @ts-ignore
      pvId: pvId,
      time: time,
    })
      .then(() => {
        onFinish();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button loading={loading} onClick={onReImplement}>
      {loading ? '正在重新抄表' : '重新抄表'}
    </Button>
  );
};

export default ElecRecord;
